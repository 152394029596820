/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import PaginationItem from './PaginationItem';

// https://media.tenor.com/si1X-OUXHKwAAAAM/senator-vreenak.gif
const Pagination = ({ pageCallback, currentPage }) => {
	return (
		<nav
			role="navigation"
			aria-label="Pagination navigation"
			className="site-text-search-pagination"
			data-testid="site-text-search-pagination"
		>
			<ul className="pagination">
				<PaginationItem
					pageCallback={pageCallback}
					currentPage={currentPage}
					pageIndex={0}
				/>
				<PaginationItem
					pageCallback={pageCallback}
					currentPage={currentPage}
					pageIndex={1}
				/>
				<li>
					<a
						href="#"
						aria-label="Go to next page"
						onClick={() => pageCallback(1)}
					>
						<i
							className="ddc-icon ddc-icon-arrow1-right"
							aria-hidden="true"
						/>
					</a>
				</li>
			</ul>
		</nav>
	);
};

Pagination.propTypes = {
	pageCallback: PropTypes.func,
	currentPage: PropTypes.number
};

export default Pagination;
