const RICH_CONTENT_SEARCH_TERMS = {
	'phone-sales': [
		'contacts',
		'phones',
		'calls',
		'sales',
		'demos',
		'managers',
		'leases'
	],
	'phone-service': [
		'contacts',
		'phones',
		'calls',
		'services',
		'oil changes',
		'battery',
		'batteries',
		'tires',
		'snow tires',
		'managers',
		'car problems'
	],
	'phone-parts': [
		'contacts',
		'phones',
		'calls',
		'parts',
		'oil changes',
		'battery',
		'batteries',
		'plows',
		'boxes',
		'tires',
		'snow tires',
		'managers'
	],
	'phone-commercial': [
		'contacts',
		'phones',
		'calls',
		'commercials',
		'managers'
	],
	email: [
		'contacts',
		'addresses',
		'sales',
		'services',
		'parts',
		'commercials',
		'messages',
		'chats',
		'emails',
		'managers'
	],
	address: ['contacts', 'addresses', 'directions', 'maps', 'locations'],
	'hours-sales': [
		'contacts',
		'hours',
		'sales',
		'times',
		'opens',
		'closes',
		'demos',
		'leasees'
	],
	'hours-service': [
		'contacts',
		'hours',
		'services',
		'times',
		'opens',
		'closes',
		'oil changes',
		'battery',
		'batteries',
		'tires',
		'snow tires',
		'car problems'
	],
	'hours-parts': [
		'contacts',
		'hours',
		'parts',
		'times',
		'opens',
		'closes',
		'oil changes',
		'battery',
		'batteries',
		'plows',
		'boxes',
		'tires',
		'snow tires'
	],
	'hours-commercial': [
		'contacts',
		'hours',
		'commercials',
		'times',
		'opens',
		'closes'
	]
};

const RICH_CONTENT_SEARCH_TERMS_ES = {
	'phone-sales': [
		'contactos',
		'telefonos',
		'teléfonos',
		'llamadas',
		'ventas',
		'manifestaciones',
		'gerentes',
		'arrendamientos'
	],
	'phone-service': [
		'contactos',
		'telefonos',
		'teléfonos',
		'llamadas',
		'servicios',
		'cambios de aceite',
		'bateria',
		'batería',
		'baterias',
		'baterías',
		'neumaticos',
		'neumáticos',
		'neumaticos para nieve',
		'neumáticos para nieve',
		'gerentes',
		'problemas con el auto'
	],
	'phone-parts': [
		'contactos',
		'telefonos',
		'teléfonos',
		'llamadas',
		'piezas',
		'cambios de aceite',
		'bateria',
		'batería',
		'baterias',
		'baterías',
		'quitanieves',
		'cajas',
		'neumaticos',
		'neumáticos',
		'neumaticos para nieve',
		'neumáticos para nieve',
		'gerentes'
	],
	'phone-commercial': [
		'contactos',
		'telefonos',
		'teléfonos',
		'llamadas',
		'comerciales',
		'gerentes'
	],
	email: [
		'contactos',
		'direcciones',
		'ventas',
		'servicios',
		'piezas',
		'comerciales',
		'mensajes',
		'charlas',
		'correos electronicos',
		'correos electrónicos',
		'gerentes'
	],
	address: ['contactos', 'direcciones', 'mapas', 'ubicaciones'],
	'hours-sales': [
		'contactos',
		'horas',
		'ventas',
		'abre',
		'cierra',
		'manifestaciones',
		'arrendatarios'
	],
	'hours-service': [
		'contactos',
		'horas',
		'servicios',
		'abre',
		'cierra',
		'cambios de aceite',
		'bateria',
		'batería',
		'baterias',
		'baterías',
		'neumaticos',
		'neumáticos',
		'neumaticos para nieve',
		'neumáticos para nieve',
		'problemas con el auto'
	],
	'hours-parts': [
		'contactos',
		'horas',
		'piezas',
		'veces',
		'abre',
		'cierra',
		'cambios de aceite',
		'bateria',
		'batería',
		'baterias',
		'baterías',
		'quitanieves',
		'cajas',
		'neumaticos',
		'neumáticos',
		'neumaticos para nieve',
		'neumáticos para nieve'
	],
	'hours-commercial': [
		'contactos',
		'horas',
		'comerciales',
		'veces',
		'abre',
		'cierra'
	]
};

const RICH_CONTENT_SEARCH_TERMS_FR = {
	'phone-sales': [
		'contacts',
		'telephones',
		'téléphones',
		'appels',
		'ventes',
		'demos',
		'démos',
		'dirigeants',
		'baux'
	],
	'phone-service': [
		'contacts',
		'telephones',
		'téléphones',
		'appels',
		'services',
		"vidanges d'huile",
		'batterie',
		'batteries',
		'pneus',
		"pneus d'hiver",
		'pneus neige',
		'dirigeants',
		'problemes de voiture',
		'problèmes de voiture'
	],
	'phone-parts': [
		'contacts',
		'telephones',
		'téléphones',
		'appels',
		'pieces',
		'pièces',
		"vidanges d'huile",
		'batterie',
		'batteries',
		'charrues',
		'boites',
		'boîtes',
		'pneus',
		"pneus d'hiver",
		'pneus neige',
		'dirigeants'
	],
	'phone-commercial': [
		'contacts',
		'telephones',
		'téléphones',
		'appels',
		'publicites',
		'publicités',
		'dirigeants'
	],
	email: [
		'contacts',
		'adresses',
		'ventes',
		'services',
		'pieces',
		'pièces',
		'publicites',
		'publicités',
		'messages',
		'discussions',
		'e-mails',
		'dirigeants'
	],
	address: ['contacts', 'adresses', 'instructions', 'cartes', 'emplacements'],
	'hours-sales': [
		'contacts',
		'heures',
		'ventes',
		'fois',
		'ouvre',
		'ferme',
		'demos',
		'démos',
		'locataires'
	],
	'hours-service': [
		'contacts',
		'heures',
		'services',
		'fois',
		'ouvre',
		'ferme',
		"vidanges d'huile",
		'batterie',
		'batteries',
		'pneus',
		"pneus d'hiver",
		'pneus neige',
		'problemes de voiture',
		'problèmes de voiture'
	],
	'hours-parts': [
		'contacts',
		'heures',
		'pieces',
		'pièces',
		'fois',
		'ouvre',
		'ferme',
		"vidanges d'huile",
		'batterie',
		'batteries',
		'charrues',
		'boites',
		'boîtes',
		'pneus',
		"pneus d'hiver",
		'pneus neige'
	],
	'hours-commercial': [
		'contacts',
		'heures',
		'publicites',
		'publicités',
		'fois',
		'ouvre',
		'ferme'
	]
};

let fuseInstance = null;

export default async function getRichContentResults(
	searchTerm,
	minimumInputCharactersToTriggerResults,
	richContentToHide = [],
	locale = 'en_US'
) {
	if (searchTerm.length < minimumInputCharactersToTriggerResults) {
		return [];
	}
	const language = locale.slice(0, 2);
	let searchTermsMap;
	switch (language) {
		case 'es':
			searchTermsMap = RICH_CONTENT_SEARCH_TERMS_ES;
			break;
		case 'fr':
			searchTermsMap = RICH_CONTENT_SEARCH_TERMS_FR;
			break;
		case 'en':
		default:
			searchTermsMap = RICH_CONTENT_SEARCH_TERMS;
			break;
	}

	if (!fuseInstance) {
		const Fuse = (await import('fuse.js')).default;
		const fuseData = Object.entries(searchTermsMap).map(
			([name, keywords]) => ({
				name,
				keywords
			})
		);

		fuseInstance = new Fuse(fuseData, {
			shouldSort: true,
			includeScore: true,
			keys: ['keywords'],
			threshold: 0.2
		});
	} else {
		// Update fuse data based on the new searchTermsMap if necessary
		const fuseData = Object.entries(searchTermsMap).map(
			([name, keywords]) => ({
				name,
				keywords
			})
		);
		fuseInstance.setCollection(fuseData);
	}

	const results = fuseInstance.search(searchTerm);

	return results
		.map((result) => result.item.name)
		.filter((name) => !richContentToHide.includes(name));
}
