import React, { useContext, useEffect, useState } from 'react';
import { usePrefs } from 'wsm-common-data';
import ContactLinksList from './ContactLinksList';
import DepartmentHoursTabs from './DepartmentHoursTabs';
import AddressCard from './AddressCard';
import { UIContext, ResultsContext } from '../../contexts';

const RichContent = () => {

	const {
		addInfo,
		richContentToDisplay,
		noResultsRichContent
	  } = useContext(ResultsContext);

	const { labels, windowId, pageAlias } = useContext(UIContext);

	const {
		addressHeadingLabel,
		addressClasses,
		addressGetDirectionsLabel,
		hoursTabsHeaderLabel
	} = usePrefs();

	const contentToDisplay = (!richContentToDisplay || richContentToDisplay.length === 0) 
    ? noResultsRichContent 
    : richContentToDisplay;

	const [contactLinksAriaLabelledById, setContactLinksAriaLabelledById] =
		useState('');
	const [addressAriaLabelledById, setAddressAriaLabelledById] = useState('');
	const [hoursTabsAriaLabelledById, setHoursTabsAriaLabelledById] =
		useState('');
	const [hoursTabsId, setHoursTabsId] = useState('');

	useEffect(() => {
		// This effect only runs once to setup specific data for this component
		// Generate unique ids
		setContactLinksAriaLabelledById(
			`${pageAlias}_${windowId}_ContactLinkAria`
		);
		setAddressAriaLabelledById(`${pageAlias}_${windowId}_AddressAria`);
		setHoursTabsAriaLabelledById(`${pageAlias}_${windowId}_HoursTabsAria`);
		setHoursTabsId(`${pageAlias}_${windowId}_HourTabsId`);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Contact Links
	const renderContactLinksList = () => {
		const { phoneNumbers = [], email = '' } = addInfo || {};
		return (
			<ContactLinksList
				phoneNumbers={phoneNumbers}
				email={email}
				richContentToDisplay={contentToDisplay}
				ariaLabelledById={contactLinksAriaLabelledById}
			/>
		);
	};

	// Address
	const renderAddress = () => {
		const hideLabel = contentToDisplay.some(
			(item) =>
				[
					'email',
					'phone-sales',
					'phone-service',
					'phone-parts',
					'phone-commercial'
				].indexOf(item) > -1
		);
		const {
			dealerInfo: {
				name = '',
				address1 = '',
				city = '',
				state = '',
				postalCode = ''
			} = {},
			mapLink = ''
		} = addInfo || {};
		return (
			<AddressCard
				name={name}
				address1={address1}
				city={city}
				state={state}
				postalCode={postalCode}
				getDirectionsLabel={labels.get(addressGetDirectionsLabel)}
				getDirectionsUrl={mapLink}
				label={!hideLabel ? labels.get(addressHeadingLabel) : ''}
				ariaLabelledById={addressAriaLabelledById}
				addressClasses={addressClasses}
			/>
		);
	};

	// Hours
	const renderHours = () => {
		const { hours = {} } = addInfo || {};
		const departments = contentToDisplay
			.filter((richContentName) => richContentName.startsWith('hours'))
			.map((richContentName) => richContentName.split('-')[1]);
		return (
			<DepartmentHoursTabs
				departments={departments}
				departmentHours={hours}
				label={labels.get(hoursTabsHeaderLabel)}
				labels={labels}
				ariaLabelledById={hoursTabsAriaLabelledById}
				tabId={hoursTabsId}
			/>
		);
	};

	const renderFunctions = {
		'phone-sales': renderContactLinksList,
		'phone-service': renderContactLinksList,
		'phone-parts': renderContactLinksList,
		'phone-commercial': renderContactLinksList,
		email: renderContactLinksList,
		address: renderAddress,
		'hours-sales': renderHours,
		'hours-service': renderHours,
		'hours-parts': renderHours,
		'hours-commercial': renderHours
	};

	if (!contentToDisplay || contentToDisplay.length === 0) {
  	  return null;
  	}

	const renderedComponents = new Set();

	return (
		<div
			className="sts-search-richcontent"
			data-testid="sts-search-richcontent"
		>
			{contentToDisplay.map((item) => {
				const renderFunction = renderFunctions[item];
				if (renderFunction && !renderedComponents.has(renderFunction)) {
					renderedComponents.add(renderFunction);
					return (
						<React.Fragment key={item}>
							{renderFunction()}
						</React.Fragment>
					);
				}
				return null;
			})}
		</div>
	);
};

export default RichContent;
