import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRequestData,usePrefs, useFlags } from 'wsm-common-data';
import { ErrorBoundary } from 'wsm-error-boundary';
import logger from 'ws-scripts/modules/logger';
import { isBrowser } from 'ws-scripts/modules/environment';
import Container from './Container';

import { g } from '../global-constants';
import '../global-sass/main.scss';

const Widget = () => {
	if (typeof window !== 'undefined') {
		window.DDC = window.DDC || {};
		window.DDC.WidgetData = window.DDC.WidgetData || {};
	}
	const { deviceType } = useRequestData();
	const { display, hideWidgetOnPages } = usePrefs();
	const {
		widgetData: { pageAlias }
	} = useSelector((state) => state);

	const isMobile = deviceType === g.MOBILE;
	const flags = useFlags();

	const isWsNav = flags['enable-ws-navigation-replacement'] === true;
	const isDisplayNavigation = display === g.NAVIGATION;

	useEffect(() => {
		if (isBrowser && isWsNav && isDisplayNavigation) {
			window.DDC.EventFired = window.DDC.EventFired || {};
			window.DDC.EventFired['ws-sts-navigation-registered'] = true;
		}
	}, []);

	if (
		hideWidgetOnPages
			.split(',')
			.some((aliasToHideOn) => aliasToHideOn.trim() === pageAlias)
	) {
		return null;
	}

	let displayMarkup = null;

	switch (display) {
		case g.INPUT:
			displayMarkup = <Container displayType={display} isMobile={isMobile} />;
			break;
		case g.EMBEDDED:
			displayMarkup = <Container displayType={display} isMobile={isMobile}/>;
			break;
		case g.DISABLED:
		case g.BUTTON:
		case g.NAVIGATION:
		default:
			displayMarkup = <Container displayType={display} isMobile={isMobile}/>;
	}

	return (
		<ErrorBoundary
			errorHandler={(error, errorInfo) => {
				const newError = new Error(
					`Encountered an error in ws-site-text-search.\n${error}`
				);
				newError.originalError = error;
				newError.originalStackTrace = errorInfo.componentStack;
				logger.error(`${newError}\n${newError.originalStackTrace}`);
			}}
		>
			{displayMarkup}
			{display === g.INPUT && (
				<div
					data-location="site-text-search"
					className="ddc-content web-api-added-placeholder text-center"
				/>
			)}
		</ErrorBoundary>
	);
};

export default Widget;
