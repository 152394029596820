import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UIContext, ResultsContext } from '../../contexts';

const EmailLink = ({ email, children }) => {
	const { labels } = useContext(UIContext);
	const { handleLinkClick } = useContext(ResultsContext);

	// Use SEND_US_AN_EMAIL as fallback if there's no children
	const linkText = children || labels.get('SEND_US_AN_EMAIL');
	const emailAriaLabel = labels.get('SEND_AN_EMAIL_TO')
		? `${labels.get('SEND_AN_EMAIL_TO')} ${email}`
		: linkText;

	const handleClick = () => {
		handleLinkClick('richContentLink', linkText, '', '');
	};

	return (
		<li>
			<a
				href={`mailto:${email}`}
				target="_blank"
				rel="noopener noreferrer"
				className="btn btn-default btn-block"
				onClick={handleClick}
				aria-label={emailAriaLabel}
			>
				<i
					className="ddc-icon ddc-icon-email mr-3"
					aria-hidden="true"
				/>
				{linkText}
			</a>
		</li>
	);
};

EmailLink.propTypes = {
	email: PropTypes.string.isRequired,
	children: PropTypes.string
};
EmailLink.defaultProps = {
	children: ''
};
export default EmailLink;
