import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from 'ddc-track-event';
import truncateValue from '../util/truncateValue';
import { UIContext, ResultsContext, SearchContext } from '../contexts';

const Chip = ({ recentSearch }) => {
	const { labels, widgetName, windowId } = useContext(UIContext);
	const { removeRecentSearch, reorderRecentSearches } =
		useContext(ResultsContext);
	const { handleChipClick } = useContext(SearchContext);

	const btnLabel = labels.get('SEARCH');
	const removeLabel = labels.get('REMOVE');

	const handleRemove = (e) => {
		e.stopPropagation();
		removeRecentSearch(recentSearch.value);
		trackEvent(widgetName, windowId, {
			element: 'search box x',
			elementCTA: recentSearch.value,
			result: 'chip removed',
			action: 'clicked'
		});
	};

	const handleClick = () => {
		handleChipClick(recentSearch.value);
		reorderRecentSearches(recentSearch);
		trackEvent(widgetName, windowId, {
			element: 'search chip',
			elementCTA: recentSearch.value,
			result: 'text search form re-submitted',
			action: 'clicked'
		});
	};

	return (
		<button
			className="chip chip-default mr-3 mt-3 ddc-font-size-small"
			type="button"
			aria-label={`${btnLabel}: ${recentSearch.value}`}
			tabIndex={0}
			onClick={handleClick}
		>
			{truncateValue(recentSearch.value)}
			<span
				role="button"
				tabIndex={0}
				className="ddc-icon ddc-icon-remove2 icon-size-2 ml-2"
				onClick={handleRemove}
				onKeyDown={(e) => {
					if (e.key === 'Enter' || e.key === ' ') {
						handleRemove(e);
					}
				}}
				aria-label={`${removeLabel}: ${recentSearch.value}`}
				data-testid="remove-icon"
			/>
		</button>
	);
};

Chip.propTypes = {
	recentSearch: PropTypes.shape({
		id: PropTypes.string,
		value: PropTypes.string
	}).isRequired
};

export default Chip;
