import { useState, useEffect } from 'react';
import { useLabels } from 'wsm-common-data';

const useAriaLive = (
	isSearchTermLongEnough,
	hasResultsLocal,
	hasEmptyResultsLocal
) => {
	const [announceText, setAnnounceText] = useState('');
	const labels = useLabels();
	const noResults = labels.get('NO_SEARCH_RESULTS_FOUND');
	const resultsReady = labels.get('SEARCH_RESULTS_READY');

	useEffect(() => {
		const timer = setTimeout(() => {
			if (isSearchTermLongEnough && hasResultsLocal) {
				setAnnounceText(resultsReady);
			} else if (isSearchTermLongEnough && hasEmptyResultsLocal) {
				setAnnounceText(noResults);
			} else {
				setAnnounceText('');
			}
		}, 30);

		return () => clearTimeout(timer);
	}, [isSearchTermLongEnough, hasResultsLocal, hasEmptyResultsLocal]);

	return announceText;
};

export default useAriaLive;
