const shouldShowRecentSearches = (
	searchTerm,
	hasResults,
	hasEmptyResults,
	recentSearches
) => {
	return (
		(!searchTerm || (!hasResults && !hasEmptyResults)) &&
		recentSearches?.length > 0 &&
		searchTerm?.length === 0
	);
};

export default shouldShowRecentSearches;
