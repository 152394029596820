import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { usePrefs, useRequestData } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { isBrowser } from 'ws-scripts/modules/environment';
import { g } from '../global-constants';
import { UIContext } from '../contexts';

const ButtonDisplay = () => {
	const { displayType, labels, openButtonRef, openModal } =
		useContext(UIContext);
	const {
		searchButtonLabel,
		searchButtonIcon,
		searchButtonIconSize,
		searchButtonStyle
	} = usePrefs();
	const useNavPortal = displayType === 'navigation';
	let label = useNavPortal ? null : labels.get(searchButtonLabel);
	const title = labels.get('SEARCH_THE_WHOLE_SITE');
	const { deviceType } = useRequestData();
	const LinkElement = useNavPortal ? 'a' : 'button';

	const iconClasses = [
		'ddc-icon',
		useNavPortal && 'ddc-nav-icon',
		searchButtonIcon ? `ddc-icon-${searchButtonIcon}` : 'ddc-icon-search',
		searchButtonIconSize && `ddc-icon-size-${searchButtonIconSize}`,
		label && 'mr-3'
	];

	const buttonClasses = [
		...(useNavPortal
			? ['ddc-nav-icon-link']
			: [
					'btn',
					`btn-${searchButtonStyle}`,
					'text-decoration-none',
					'btn-no-decoration'
			  ])
	];

	let navigationTarget;
	if (useNavPortal && isBrowser) {
		navigationTarget = document.querySelector(
			'.ws-site-text-search-navigation-target'
		);
		if (navigationTarget) {
			if (deviceType === g.MOBILE) {
				const velocityButton =
					navigationTarget.querySelector('.btn-placeholder');
				if (velocityButton) {
					// remove the velocity label to make way for the portal
					navigationTarget.removeChild(velocityButton);
				}
				// on mobile, See if the 'data-search-label' has a value.
				label =
					navigationTarget.getAttribute('data-search-label') || null;
				const navButtonClasses = [
					'btn',
					'ddc-btn-nav',
					'btn-no-decoration',
					'border-left',
					'btn-search',
					'btn-default',
					'text-center'
				];
				buttonClasses.push(...navButtonClasses);
			}
		}
	}

	const button = (
		<LinkElement
			type="button"
			onClick={openModal}
			aria-label={label || title}
			className={setClassNames(buttonClasses)}
			data-testid="sts-buttondisplay"
			ref={openButtonRef}
		>
			<i className={setClassNames(iconClasses)} aria-hidden="true" />
			{deviceType === g.MOBILE && label ? (
				<span className="ddc-btn-text">{label}</span>
			) : (
				label
			)}
		</LinkElement>
	);

	const buttonInNavigation = navigationTarget
		? ReactDOM.createPortal(button, navigationTarget)
		: null;

	return useNavPortal ? buttonInNavigation : button;
};

export default ButtonDisplay;
