export const transformInventoryResults = (results, inventorySrpPath) => {
	let transformedResults = [];
	if (results.length > 1 || (results.length === 1 && results[0].count)) {
		transformedResults = results
			.filter((item) => item.count > 0)
			.map(({ suggestion, count, facetInfo }) => {
				return {
					title: suggestion,
					count,
					facets: Object.keys(facetInfo).join(','),
					path: facetInfo
						? `${inventorySrpPath}?${Object.keys(facetInfo)
								.map(
									(key) =>
										`${encodeURIComponent(
											key
										)}=${encodeURIComponent(
											facetInfo[key]
										)}`
								)
								.join('&')}`
						: inventorySrpPath
				};
			});
	}
	return transformedResults;
};

export const makeAllLinkPath = (
	results,
	inventorySrpPath,
	transformedResults
) => {
	const validResults = results.filter(({ count }) => count > 0);

	if (
		validResults.length > 0 &&
		validResults[0].facetInfo?.model !== undefined
	) {
		const models = validResults.map((item) => item.facetInfo.model);

		const uniqueModels = [...new Set(models)];
		const queryString = `make=${
			validResults[0].facetInfo.make
		}&${uniqueModels
			.map((model) => `model=${encodeURIComponent(model)}`)
			.join('&')}`;
		return `${inventorySrpPath}?${queryString}`;
	} else if (validResults.length > 0 && validResults?.[0]?.count > 1) {
		return transformedResults?.[0]?.path;
	} else {
		return '';
	}
};
