// This file is intended for global constants that are shared across
// the application. Use this file sparingly. For constants with a single
// usage, it can be better to include them in the module scope of the
// file they're used in.

export const g = {
	EMBEDDED: 'embedded',
	INPUT: 'input',
	DISABLED: 'disabled',
	BUTTON: 'button',
	NAVIGATION: 'navigation',
	DESKTOP: 'DESKTOP',
	MOBILE: 'MOBILE',
	INVENTORY: 'inventory',
	PAGE: 'page',
	CLOSE_BUTTON: 'close button',
	RECENT_SEARCHES_KEY: 'ws-site-text-search-recent-search-items'
};
