import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import { trackEvent } from 'ddc-track-event';
import VehicleCards from './VehicleCards';
import SearchLinks from './SearchLinks';
import RichContent from './richContent/RichContent';
import ContentColumnizer from './ContentColumnizer';
import { UIContext, ResultsContext } from '../contexts';

const InventoryTabs = ({ hasRichContentToDisplay }) => {
	const {
		activeInventoryTab,
		setActiveInventoryTab,
		disabledTabs,
		inventoryResults,
		handleLinkClick,
		pageSearchResults,
		hasSingleVehicle
	} = useContext(ResultsContext);

	const { inputWidth, labels, widgetName, windowId } = useContext(UIContext);

	const tabs = [
		{ key: 'new', label: 'TAB_NEW' },
		{ key: 'used', label: 'TAB_PRE_OWNED' },
		{ key: 'certified', label: 'TAB_CERTIFIED' }
	];

	const allVehiclesPath =
		inventoryResults[activeInventoryTab]?.allVehiclesPath || '';
	const viewLinkLabel = labels.get('VIEW_ALL_VEHICLES_SITE_SEARCH');
	const handleClick = () => {
		handleLinkClick('allInventoryLink', viewLinkLabel, '', '');
	};
	const handleTabClick = (tab) => {
		trackEvent(widgetName, windowId, {
			action: 'clicked',
			element: `${tab.key} tab`,
			elementCTA: labels.get(tab.label),
			result: `${tab.key} > navigated to ${tab.key} results`,
			nonInteractive: false
		});
	};

	return (
		<div className="mb-4 tabs tabs-fill">
			<Tabs
				onSelect={(e) => {
					setActiveInventoryTab(e);
					handleTabClick(tabs.find((tab) => tab.key === e));
				}}
				className="inventory-tabs mx-4 ddc-font-size-large"
				activeKey={activeInventoryTab}
				id="inventory-tabs"
			>
				{tabs.map((tab) => (
					<Tab
						className="py-4 ddc-font-size-base"
						title={labels.get(tab.label)}
						eventKey={tab.key}
						key={tab.key}
						disabled={disabledTabs?.some(
							(disabledTab) => disabledTab === tab.key
						)}
					/>
				))}
			</Tabs>
			{allVehiclesPath && !hasSingleVehicle && (
				<a
					onClick={handleClick}
					href={allVehiclesPath}
					className="font-weight-bold py-3 px-2"
				>
					<span>
						{viewLinkLabel}
						<i
							className="ddc-icon ddc-icon-arrow-details ml-3"
							aria-hidden="true"
						/>
					</span>
				</a>
			)}
			<div className="row">
				<ContentColumnizer
					inputWidth={inputWidth}
					vehicles={
						inventoryResults[activeInventoryTab]?.vehicles?.length >
						0
					}
				>
					{inventoryResults[activeInventoryTab]?.vehicles?.length >
						0 && (
						<VehicleCards
							vehicles={
								inventoryResults[activeInventoryTab].vehicles
							}
						/>
					)}
					{inventoryResults[activeInventoryTab]?.suggestions?.length >
						0 && (
						<SearchLinks
							type="inventory"
							data={
								inventoryResults[activeInventoryTab].suggestions
							}
						/>
					)}
					{pageSearchResults?.length > 0 && (
						<SearchLinks type="page" data={pageSearchResults} />
					)}
					{hasRichContentToDisplay && <RichContent />}
				</ContentColumnizer>
			</div>
		</div>
	);
};

InventoryTabs.propTypes = {
	hasRichContentToDisplay: PropTypes.bool
};

export default InventoryTabs;
