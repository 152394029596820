import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { setClassNames } from 'ddc-classnames-js';
import RichContent from './richContent/RichContent';
import useFocusTrap from '../hooks/useFocusTrap';
import InventoryTabs from './InventoryTabs';
import VehicleCards from './VehicleCards';
import SearchLinks from './SearchLinks';
import NoResults from './NoResults';
import RecentSearches from './RecentSearches';
import shouldShowRecentSearches from '../util/shouldShowRecentSearches';
import hasEmptyResults from '../util/hasEmptyResults';
import hasResults from '../util/hasResults';
import ContentColumnizer from './ContentColumnizer';
import useAriaLive from '../hooks/useAriaLive';
import isEnoughAddData from '../util/isEnoughAddData';
import { SearchContext, UIContext, ResultsContext } from '../contexts';

const DropDown = () => {

	const { 
		searchTerm, 
		numberOfCharactersForFirstSearchNumber,
		fetchingResults
	  } = useContext(SearchContext);
	  
	  const { 
		dropDownRef,
		dropDownPosition,
		inputRef,
		inputElementRef,
		inputWidth,
		labels,
		modalIsOpen,
		closeModal
	  } = useContext(UIContext);
	  
	  const {
		preparedInventoryResults,
		pageSearchResults,
		richContentToDisplay,
		recentSearches,
		handleLinkClick,
		addInfo,
		noResultsLinks,
		showTabs,
		hasSingleVehicle
	  } = useContext(ResultsContext);

	  const { suggestions, vehicles, allVehiclesPath } = preparedInventoryResults || {};

	const isSearchTermLongEnough =
		searchTerm?.length >= numberOfCharactersForFirstSearchNumber;

	const hasResultsLocal = hasResults(pageSearchResults, suggestions, vehicles);

	const hasEmptyResultsLocal = hasEmptyResults(
		pageSearchResults,
		suggestions,
		vehicles
	);

	const announceText = useAriaLive(
		isSearchTermLongEnough,
		hasResultsLocal,
		hasEmptyResultsLocal
	);

	const hasRichContentToDisplay = isEnoughAddData(
		richContentToDisplay,
		addInfo
	);

	const shouldRenderNoResults =
		!hasResultsLocal &&
		hasEmptyResultsLocal &&
		!hasRichContentToDisplay &&
		!fetchingResults;

	useFocusTrap(
		[inputRef, dropDownRef],
		dropDownRef,
		null,
		inputElementRef,
		null,
		modalIsOpen,
		closeModal
	);

	const classNames = [
		'sts-search-dropdown',
		'ddc-box-1',
		'bg-contrast-low',
		'p-4',
		!modalIsOpen && 'hidden',
		'shadow'
	];

	if (
		!recentSearches?.length &&
		(!searchTerm ||
			!isSearchTermLongEnough ||
			(pageSearchResults === undefined &&
				suggestions === undefined &&
				vehicles === undefined))
	) {
		return null;
	}
	const viewLinkLabel = labels.get('VIEW_ALL_VEHICLES_SITE_SEARCH');
	const handleClick = () => {
		handleLinkClick('allInventoryLink', viewLinkLabel, '', '');
	};

	const dropDownContent = (
		<div
			ref={dropDownRef}
			className={setClassNames(classNames)}
			data-testid="sts-input-dropdown"
			data-widget-name="ws-site-text-search"
			role="dialog"
			aria-labelledby={
				searchTerm ? 'results-heading' : 'recent-searches-heading'
			}
			aria-modal="true"
			style={{
				position: 'absolute',
				top: dropDownPosition.top,
				left: dropDownPosition.left,
				width: inputWidth,
				minHeight: '117px'
			}}
		>
			<div aria-live="assertive" className="sr-only">
				{announceText}
			</div>

			{shouldShowRecentSearches(
				searchTerm,
				hasResultsLocal,
				hasEmptyResultsLocal,
				recentSearches
			) && (
				<RecentSearches/>
			)}

			{isSearchTermLongEnough && (
				<div>
					<h2 id="results-heading" className="sr-only">
						{labels.get('SEARCH_RESULTS')}
					</h2>
					{showTabs ? (
						<InventoryTabs
							hasRichContentToDisplay={hasRichContentToDisplay}
						/>
					) : (
						<>
							{allVehiclesPath &&
								!shouldRenderNoResults &&
								!hasSingleVehicle && (
									<a
										onClick={handleClick}
										href={allVehiclesPath}
										className="font-weight-bold py-3 px-4"
									>
										<span>
											{viewLinkLabel}
											<i
												className="ddc-icon ddc-icon-arrow-details ml-3"
												aria-hidden="true"
											/>
										</span>
									</a>
								)}
							<ContentColumnizer
								vehicles={vehicles?.length > 0}
							>
								{vehicles?.length > 0 ? (
									<VehicleCards
										vehicles={vehicles}
									/>
								) : null}
								{suggestions?.length > 0 ? (
									<SearchLinks
										type="inventory"
										data={suggestions}
									/>
								) : null}
								{pageSearchResults?.length > 0 ? (
									<SearchLinks
										type="page"
										data={pageSearchResults}
									/>
								) : null}
								{shouldRenderNoResults && (
									<>
										<NoResults />
										<SearchLinks
											type="no-results"
											data={noResultsLinks}
										/>
									</>
								)}
								{(hasRichContentToDisplay ||
									shouldRenderNoResults) && (
									<RichContent/>
								)}
							</ContentColumnizer>
						</>
					)}
				</div>
			)}
		</div>
	);
	return ReactDOM.createPortal(dropDownContent, document.body);
};

export default DropDown;
