import React from 'react';
import { useLabels } from 'wsm-common-data';

const NoResults = () => {
	const labels = useLabels();
	const message = labels.get('SORRY_NO_RESULTS');

	return (
		<div
			data-testid="sts-no-results"
			aria-live="polite"
			aria-atomic="true"
			role="alert"
		>
			<div className="p-4 text-body">
				<strong>{message}...</strong>
			</div>
		</div>
	);
};

export default NoResults;
