import React, {
	useEffect,
	useRef,
	useState,
	useMemo,
	useCallback
} from 'react';
import {
	useFlags,
	usePrefs,
	useSitemap,
	useRequestData,
	useLabels
} from 'wsm-common-data';
import { isBrowser } from 'ws-scripts/modules/environment';
import { useSelector } from 'react-redux';
import {
	addNewRelicPageAction,
	setNewRelicCustomAttribute
} from 'ddc-new-relic';
import { trackEvent } from 'ddc-track-event';
import throttle from '../util/throttle';
import closeModalHelper from '../util/closeModalHelper';
import { g } from '../global-constants';
import DropDown from '../components/DropDown';
import InputDisplay from '../components/InputDisplay';
import fetchInventory from '../util/fetchInventory';
import fetchPages from '../util/fetchPages';
import {
	transformInventoryResults,
	makeAllLinkPath
} from '../util/transformInventoryResults';
import getRichContentResults from '../util/getRichContentResults';
import ButtonDisplay from '../components/ButtonDisplay';
import FlyOut from '../components/FlyOut';
import StaticEmbedded from '../components/StaticEmbedded';

import { SearchContext, UIContext, ResultsContext } from '../contexts';

// eslint-disable-next-line react/prop-types
const Container = ({ displayType, isMobile }) => {
	if (typeof window !== 'undefined') {
		window.DDC = window.DDC || {};
		window.DDC.WidgetData = window.DDC.WidgetData || {};
	}

	const isEmbedded = displayType === g.EMBEDDED;
	const labels = useLabels();
	const flags = useFlags();
	const sitemap = useSitemap();
	const {
		numberOfCharactersForFirstSearch,
		overrideAllInventoryAlias,
		noResultsLinks,
		vehicleOrder
	} = usePrefs();

	const numberOfCharactersForFirstSearchNumber = parseInt(
		numberOfCharactersForFirstSearch,
		10
	);

	const { windowId, locale, widgetName } = useRequestData();
	const inputRef = useRef(null);
	const dropDownRef = useRef(null);
	const embeddedDropDownRef = useRef(null);
	const openButtonRef = useRef(null);
	const inputElementRef = useRef(null);

	const {
		accountId,
		siteId,
		widgetSearchServiceUri,
		configTypes,
		pageAlias
	} = useSelector((state) => state.widgetData);

	const [dropDownPosition, setDropDownPosition] = useState({
		top: 0,
		left: 0
	});

	const [inputWidth, setInputWidth] = useState(0);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [searchTermEmbedded, setSearchTermEmbedded] = useState('');
	const [inventorySearchResults, setInventorySearchResults] = useState();
	const [pageSearchResults, setPageSearchResults] = useState();
	const [pageSearchResultsEmbedded, setPageSearchResultsEmbedded] =
		useState();
	const [addInfo, setAddInfo] = useState();
	const [richContentToDisplay, setRichContentToDisplay] = useState([]);
	const [richContentToDisplayEmbedded, setRichContentToDisplayEmbedded] =
		useState([]);
	const [fetchingResults, setFetchingResults] = useState(false);
	const [screenWidth, setScreenWidth] = useState(0);
	const [isEmbeddedOverrideActive, setIsEmbeddedOverrideActive] =
		useState(false);
	const [hasSingleVehicle, setHasSingleVehicle] = useState(false);
	const noResultsRichContent = ['phone-sales', 'hours-sales', 'email'];
	const [activeInventoryTab, setActiveInventoryTab] = useState('new');
	const [disabledTabs, setDisabledTabs] = useState([]);
	const renderStartTimeRef = useRef(null);

	const filteredInventorySearchResultsByTab = useMemo(
		() =>
			flags['enable-conditions-tabs-site-text-search'] === true
				? {
						new: {
							vehicles:
								inventorySearchResults?.new?.vehicles || [],
							suggestions:
								inventorySearchResults?.new?.suggestions || [],
							allVehiclesPath:
								inventorySearchResults?.new?.allVehiclesPath ||
								''
						},
						used: {
							vehicles:
								inventorySearchResults?.used?.vehicles || [],
							suggestions:
								inventorySearchResults?.used?.suggestions || [],
							allVehiclesPath:
								inventorySearchResults?.used?.allVehiclesPath ||
								''
						},
						certified: {
							vehicles:
								inventorySearchResults?.certified?.vehicles ||
								[],
							suggestions:
								inventorySearchResults?.certified
									?.suggestions || [],
							allVehiclesPath:
								inventorySearchResults?.certified
									?.allVehiclesPath || ''
						}
				  }
				: inventorySearchResults,
		[inventorySearchResults, flags]
	);

	const [showTabs, setShowTabs] = useState(false);

	useEffect(() => {
		if (
			flags['enable-conditions-tabs-site-text-search'] &&
			(filteredInventorySearchResultsByTab?.new?.vehicles?.length ||
				filteredInventorySearchResultsByTab?.used?.vehicles?.length ||
				filteredInventorySearchResultsByTab?.certified?.vehicles
					?.length)
		) {
			setShowTabs(true);
		} else {
			setShowTabs(false);
		}
		const allVehicles = [
			...(filteredInventorySearchResultsByTab?.new?.vehicles || []),
			...(filteredInventorySearchResultsByTab?.used?.vehicles || []),
			...(filteredInventorySearchResultsByTab?.certified?.vehicles || []),
			...(inventorySearchResults?.vehicles || [])
		];
		setHasSingleVehicle(allVehicles.length === 1);
	}, [filteredInventorySearchResultsByTab, inventorySearchResults, flags]);

	const hasSearchResults =
		pageSearchResults?.length > 0 ||
		inventorySearchResults?.suggestions?.length > 0 ||
		inventorySearchResults?.vehicles?.length > 0 ||
		showTabs;

	const NO_RESULTS_LINKS = useMemo(() => {
		return noResultsLinks.split(',').map((item) => {
			const trimmedItem = item.trim();
			return {
				alias: trimmedItem,
				title: labels.get(`${trimmedItem}_SEARCH_LINK`),
				path: sitemap.get(trimmedItem),
				icon: trimmedItem.startsWith('INVENTORY_LISTING')
					? 'ddc-icon-single-vehicle'
					: 'ddc-icon-arrow-circle-right'
			};
		});
	}, [noResultsLinks, labels, sitemap]);

	const fetchAddInfo = useCallback(async () => {
		if (typeof window !== 'undefined' && !addInfo) {
			// this code eventually used for wsm-contact-transformer which looks for 'iPhone'
			// in userAgent so doing a tricky thing so we do not have to pass whole userAgent

			const userAgent = window.navigator.userAgent.includes('iPhone')
				? 'iPhone'
				: 'not-apple-mobile-device';

			const payload = {
				timeout: flags['wsm-account-data-distributor-timeout']
			};

			const fetchedAddInfo = await fetch(
				`${widgetSearchServiceUri}/addData/${accountId}/${userAgent}/${siteId}/${locale}/${widgetName}`,
				{
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(payload)
				}
			);
			const info = await fetchedAddInfo.json();

			setAddInfo(info);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		accountId,
		addInfo,
		locale,
		siteId,
		widgetName,
		widgetSearchServiceUri
	]);

	const updateDropDownPosition = useCallback(() => {
		if (inputRef.current) {
			const inputRect = inputRef.current.getBoundingClientRect();
			const newTop = inputRect.bottom + window.scrollY;
			const newLeft = inputRect.left + window.scrollX;

			// Ensure the dropdown's top position is always below the input display's bottom position
			const minTop = inputRect.bottom + window.scrollY;

			const adjustedTop = Math.max(newTop, minTop);

			setDropDownPosition({
				top: adjustedTop,
				left: newLeft
			});
			setInputWidth(inputRect.width);
		}
	}, [inputRef]);

	useEffect(() => {
		// Initialize inputWidth on component mount for StaticEmbedded
		if (displayType === 'embedded' && inputRef.current) {
			setInputWidth(inputRef.current.offsetWidth);
		}
	}, [displayType]);

	const getInitialRecentSearches = useCallback(() => {
		if (typeof localStorage === 'undefined') {
			return [];
		}
		try {
			const storedSearches = localStorage.getItem(g.RECENT_SEARCHES_KEY);
			return storedSearches ? JSON.parse(storedSearches) : [];
		} catch (error) {
			return [];
		}
	}, []);

	const [recentSearches, setRecentSearches] = useState(
		getInitialRecentSearches
	);

	const RESULTS_PAGE_ALIAS = 'SITE_TEXT_SEARCH_RESULTS';
	const FOUR_ZERO_FOUR_PAGE_ALIAS = '404';
	const pageNotFoundPath = sitemap.get(FOUR_ZERO_FOUR_PAGE_ALIAS);
	const resultsPageAlias =
		sitemap.get(RESULTS_PAGE_ALIAS) || pageNotFoundPath;

	const hasFetchedAddInfo = useRef(false);

	const openModal = useCallback(() => {
		if (!hasFetchedAddInfo.current) {
			fetchAddInfo();
			hasFetchedAddInfo.current = true;
		}
		if (
			(!modalIsOpen &&
				!window?.DDC?.WidgetData?.[widgetName]?.modalIsOpen &&
				(recentSearches?.length > 0 ||
					hasSearchResults ||
					searchTerm?.length >
						numberOfCharactersForFirstSearchNumber)) ||
			displayType !== 'input' ||
			richContentToDisplay.length > 0 ||
			isMobile
		) {
			window.DDC.WidgetData[widgetName] = {
				modalIsOpen: true
			};
			trackEvent(widgetName, windowId, {
				element: 'search box',
				result: 'search modal initiated',
				action: 'clicked'
			});
			setRecentSearches(getInitialRecentSearches);
			setNewRelicCustomAttribute('wsSiteTextSearch-modalOpened', 'true');
			setModalIsOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalIsOpen, widgetName, searchTerm]);

	const closeModal = useCallback(() => {
		closeModalHelper(
			widgetName,
			windowId,
			setModalIsOpen,
			setSearchTerm,
			isEmbeddedOverrideActive,
			setIsEmbeddedOverrideActive
		);
	}, []);

	const updateRecentSearches = useCallback(() => {
		const sanitizedTerm = searchTerm?.trim()?.substring(0, 100);
		if (!sanitizedTerm) {
			return;
		}
		try {
			const searches =
				JSON.parse(localStorage.getItem(g.RECENT_SEARCHES_KEY)) || [];
			const filteredSearch = searches.filter(
				(search) => search.value !== sanitizedTerm
			);

			if (filteredSearch.length >= 10) {
				filteredSearch.pop();
			}

			const newSearch = { value: sanitizedTerm };
			const newRecentSearches = [newSearch, ...filteredSearch];
			localStorage.setItem(
				g.RECENT_SEARCHES_KEY,
				JSON.stringify(newRecentSearches)
			);
			setRecentSearches(newRecentSearches);
		} catch (error) {
			// add New Relic error tracking
		}
	}, [searchTerm]);

	const removeRecentSearch = useCallback((value) => {
		setRecentSearches((prevState) => {
			const updatedSearches = prevState.filter(
				(search) => search.value !== value
			);
			try {
				localStorage.setItem(
					g.RECENT_SEARCHES_KEY,
					JSON.stringify(updatedSearches)
				);
			} catch (error) {
				// add New Relic error tracking
			}

			if (updatedSearches.length === 0) {
				inputElementRef.current?.focus();
			}

			return updatedSearches;
		});
	}, []);

	const reorderRecentSearches = useCallback(
		(clickedSearch) => {
			const updatedSearches = recentSearches.filter(
				(search) => search.value !== clickedSearch.value
			);
			const newRecentSearches = [clickedSearch, ...updatedSearches];

			setRecentSearches(newRecentSearches);

			try {
				localStorage.setItem(
					g.RECENT_SEARCHES_KEY,
					JSON.stringify(newRecentSearches)
				);
			} catch (error) {
				// Add New Relic error tracking
			}
		},
		[recentSearches]
	);

	const getPageResults = useCallback(
		async (query) => {
			const limit = displayType === 'embedded' ? 25 : 5;
			const pageSearchResponse = await fetchPages({
				searchTerm: query,
				siteId,
				locale,
				widgetSearchServiceUri,
				limit
			});

			return pageSearchResponse?.sort((a, b) => b.score - a.score);
		},
		[displayType, siteId, locale, widgetSearchServiceUri]
	);

	const firstRender = useRef(true);
	const getInventoryResults = useCallback(
		async (query, conditionFilter) => {
			if (displayType === 'embedded' && firstRender.current) {
				firstRender.current = false;
				return undefined;
			}

			const inventoryFetchTimeout =
				flags['ws-site-text-search-inventory-client-timeout'];
			let inventorySrpPath;
			switch (conditionFilter) {
				case 'NEW':
					inventorySrpPath =
						sitemap.get('INVENTORY_LISTING_DEFAULT_AUTO_NEW') ||
						pageNotFoundPath;
					break;
				case 'USED':
					inventorySrpPath =
						sitemap.get('INVENTORY_LISTING_DEFAULT_AUTO_USED') ||
						pageNotFoundPath;
					break;
				case 'CERTIFIED':
					inventorySrpPath =
						sitemap.get(
							'INVENTORY_LISTING_DEFAULT_AUTO_CERTIFIED_USED'
						) || pageNotFoundPath;
					break;
				default:
					inventorySrpPath =
						(overrideAllInventoryAlias
							? sitemap.get(overrideAllInventoryAlias)
							: sitemap.get(
									'INVENTORY_LISTING_DEFAULT_AUTO_ALL'
							  )) || pageNotFoundPath;
					break;
			}

			const inventorySearchResponse = await fetchInventory({
				searchTerm: query,
				siteId,
				locale,
				widgetSearchServiceUri,
				configTypes,
				resultsPageAlias,
				inventoryFetchTimeout,
				shouldReturnCardsAlso: true,
				vehicleOrder,
				conditionFilter
			});
			if (inventorySearchResponse) {
				const {
					suggestions,
					vehicles,
					searchTerm: responseSearchTerm
				} = inventorySearchResponse;

				if (responseSearchTerm === query.replace('+', ' ')) {
					let transformedSuggestions = [];
					let sortedSuggestions = [];
					if (suggestions && suggestions.length) {
						sortedSuggestions = suggestions?.sort(
							(a, b) => b.count - a.count
						);
						transformedSuggestions = transformInventoryResults(
							sortedSuggestions,
							inventorySrpPath
						);
					}
					return {
						suggestions: transformedSuggestions,
						allVehiclesPath: makeAllLinkPath(
							sortedSuggestions,
							inventorySrpPath,
							transformedSuggestions
						),
						vehicles
					};
				}
			}
			return [];
			// eslint-disable-next-line max-len
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			displayType,
			firstRender,
			flags,
			siteId,
			locale,
			widgetSearchServiceUri,
			configTypes,
			resultsPageAlias,
			vehicleOrder,
			pageNotFoundPath,
			overrideAllInventoryAlias,
			sitemap
		]
	);

	const handleTabs = (inventoryResponse) => {
		const tabs = ['new', 'used', 'certified'];
		const tabsToDisable = tabs.filter(
			(condition) => !inventoryResponse[condition]?.vehicles?.length
		);
		setDisabledTabs(tabsToDisable);

		const activeTab = tabs.find((tab) => !tabsToDisable.includes(tab));

		setActiveInventoryTab(activeTab || 'new');
	};

	const fetchResults = useCallback(
		async (query) => {
			let pageSearchResponse;
			let inventorySearchResponse;

			if (flags['enable-conditions-tabs-site-text-search']) {
				const [
					pageSearchResponseResult,
					newInventorySearchResponse,
					usedInventorySearchResponse,
					certifiedInventorySearchResponse
				] = await Promise.all([
					getPageResults(query),
					getInventoryResults(query, 'NEW'),
					getInventoryResults(query, 'USED'),
					getInventoryResults(query, 'CERTIFIED')
				]);

				pageSearchResponse = pageSearchResponseResult;

				inventorySearchResponse = {
					new: newInventorySearchResponse,
					used: usedInventorySearchResponse,
					certified: certifiedInventorySearchResponse
				};
			} else {
				const [
					pageSearchResponseResult,
					singleInventorySearchResponse
				] = await Promise.all([
					getPageResults(query),
					getInventoryResults(query)
				]);

				pageSearchResponse = pageSearchResponseResult;
				inventorySearchResponse = singleInventorySearchResponse;
			}

			setPageSearchResults(pageSearchResponse);
			setInventorySearchResults(inventorySearchResponse);

			if (flags['enable-conditions-tabs-site-text-search']) {
				handleTabs(inventorySearchResponse);
			}

			const richContentToHide = [];

			const richContentResults = await getRichContentResults(
				query,
				numberOfCharactersForFirstSearchNumber,
				richContentToHide,
				locale
			);
			setRichContentToDisplay(richContentResults);

			if (query.length >= numberOfCharactersForFirstSearchNumber) {
				setModalIsOpen(true);
			}

			const resultTypes = [];
			const inventoryTypes = [];
			if (inventorySearchResponse?.new?.vehicles?.length) {
				inventoryTypes.push('New');
			}
			if (inventorySearchResponse?.used?.vehicles?.length) {
				inventoryTypes.push('Used');
			}
			if (inventorySearchResponse?.certified?.vehicles?.length) {
				inventoryTypes.push('Certified');
			}
			if (inventoryTypes.length) {
				resultTypes.push(
					`${inventoryTypes.join(' and ')} > Inventory Results`
				);
			} else if (
				!flags['enable-conditions-tabs-site-text-search'] &&
				(inventorySearchResponse?.suggestions?.length ||
					inventorySearchResponse?.vehicles?.length)
			) {
				resultTypes.push('Inventory Results');
			}
			if (pageSearchResponse?.length) {
				resultTypes.push('Non-Inventory Results');
			}
			const result = resultTypes.length
				? `${resultTypes.join(' and ')} Populated`
				: 'No Results Populated';
			trackEvent(widgetName, windowId, {
				element: 'search query typed in',
				result,
				action: 'impression',
				fieldValue: query,
				nonInteractive: false
			});

			setFetchingResults(false);
			const renderEndTime = performance.now();
			const renderDuration = renderEndTime - renderStartTimeRef.current;
			if (isBrowser && window) {
				addNewRelicPageAction('wsSiteTextSearchDebug', {
					eventName: 'ws-site-text-search render duration',
					renderDuration
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			getPageResults,
			getInventoryResults,
			numberOfCharactersForFirstSearchNumber,
			locale,
			widgetName,
			windowId
		]
	);

	const hasNoResults = !!(
		searchTerm &&
		pageSearchResults?.length === 0 &&
		// if we are using the tabs, we need to check if all of them are empty
		// if we are not using the tabs, we just check if the vehicles array is empty
		(flags['enable-conditions-tabs-site-text-search'] === true
			? filteredInventorySearchResultsByTab?.new?.length === 0 &&
			  filteredInventorySearchResultsByTab?.used?.length === 0 &&
			  filteredInventorySearchResultsByTab?.certified?.length === 0
			: inventorySearchResults?.vehicles?.length === 0) &&
		richContentToDisplay?.length === 0
	);

	const fetchEmbeddedResults = useCallback(async (query) => {
		const [pageSearchResponse] = await Promise.all([
			getPageResults(query),
			getInventoryResults(query)
		]);

		setPageSearchResultsEmbedded(pageSearchResponse);

		const richContentToHide = [];

		const richContentResults = await getRichContentResults(
			query,
			numberOfCharactersForFirstSearchNumber,
			richContentToHide,
			locale
		);
		setRichContentToDisplayEmbedded(richContentResults);
	}, []);

	const handleLinkClick = useCallback(
		(
			linkType,
			elementCTA,
			fieldValue,
			result,
			addRecentSearches = true
		) => {
			if (addRecentSearches) {
				updateRecentSearches();
			}

			const prependTab = showTabs ? `${activeInventoryTab} > ` : '';

			switch (linkType) {
				case 'vehicleLink':
					trackEvent(widgetName, windowId, {
						action: 'clicked',
						element: 'vehicle card',
						elementCTA: 'autocomplete vehicle card',
						result: `${prependTab}navigated to VDP`,
						fieldValue: searchTerm
					});
					break;
				case 'richContentLink':
					trackEvent(widgetName, windowId, {
						action: 'clicked',
						element: 'rich content autocomplete result',
						elementCTA: elementCTA || '',
						result: `${prependTab}${
							result || 'navigated to rich content destination'
						}`,
						fieldValue: searchTerm
					});
					break;
				case 'inventoryLink':
					trackEvent(widgetName, windowId, {
						action: 'clicked',
						element: 'autocomplete inventory result',
						elementCTA: elementCTA || '',
						result: `${prependTab}text search form submitted`,
						fieldValue: fieldValue || ''
					});
					break;
				case 'pageLink':
					trackEvent(widgetName, windowId, {
						action: 'clicked',
						element: 'autocomplete quick link result',
						elementCTA: elementCTA || '',
						result: `${prependTab}text search form submitted`,
						fieldValue: fieldValue || ''
					});
					break;
				case 'allInventoryLink':
					trackEvent(widgetName, windowId, {
						action: 'clicked',
						element: 'All inventory link',
						elementCTA: elementCTA || '',
						result: `${prependTab}text search form submitted`,
						fieldValue: searchTerm
					});
					break;
				case 'noResultsLink':
					trackEvent(widgetName, windowId, {
						action: 'clicked',
						element: 'No results link',
						elementCTA: elementCTA || '',
						result: 'Navigated to CTA destination',
						fieldValue: searchTerm
					});
					break;
				default:
					break;
			}
		},
		[
			updateRecentSearches,
			widgetName,
			windowId,
			searchTerm,
			showTabs,
			activeInventoryTab
		]
	);

	const handleQuerySubmission = useCallback(
		(event) => {
			event?.preventDefault();
			if (fetchingResults) return;
			setNewRelicCustomAttribute('wsSiteTextSearch-onSubmit', 'true');

			const allVehicles = [
				...(filteredInventorySearchResultsByTab?.new?.vehicles || []),
				...(filteredInventorySearchResultsByTab?.used?.vehicles || []),
				...(filteredInventorySearchResultsByTab?.certified?.vehicles ||
					[]),
				...(inventorySearchResults?.vehicles || [])
			];

			const allSuggestions = [
				...(filteredInventorySearchResultsByTab?.new?.suggestions ||
					[]),
				...(filteredInventorySearchResultsByTab?.used?.suggestions ||
					[]),
				...(filteredInventorySearchResultsByTab?.certified
					?.suggestions || []),
				...(inventorySearchResults?.suggestions || [])
			];

			const singleVehiclePath =
				allVehicles.length === 1 ? allVehicles[0]?.path : null;

			if (singleVehiclePath) {
				setNewRelicCustomAttribute(
					'wsSiteTextSearch-onSubmitSingleVehicleCard',
					'true'
				);
				handleLinkClick('vehicleLink', '', '', '');
				window.location.href = singleVehiclePath;
			} else if (hasNoResults) {
				setNewRelicCustomAttribute(
					'wsSiteTextSearch-onSubmitNoResultsNotRedirected',
					'true'
				);
				event.preventDefault();
			} else if (allSuggestions?.length) {
				setNewRelicCustomAttribute(
					'wsSiteTextSearch-onSubmitRedirectedToAllInventory',
					'true'
				);
				if (showTabs) {
					switch (activeInventoryTab) {
						case 'new':
							window.location.href = `${inventorySearchResults?.new?.allVehiclesPath}`;
							break;
						case 'used':
							window.location.href = `${inventorySearchResults?.used?.allVehiclesPath}`;
							break;
						case 'certified':
							window.location.href = `${inventorySearchResults?.certified?.allVehiclesPath}`;
							break;
						default:
							window.location.href = `${inventorySearchResults?.allVehiclesPath}`;
							break;
					}
				} else {
					window.location.href = `${inventorySearchResults?.allVehiclesPath}`;
				}
			} else {
				setNewRelicCustomAttribute(
					'wsSiteTextSearch-onSubmitRedirectedToSearchResults',
					'true'
				);
				window.location.href = `${resultsPageAlias}?search=${encodeURIComponent(
					searchTerm
				)}`;
			}
		},
		[
			fetchingResults,
			inventorySearchResults,
			hasNoResults,
			handleLinkClick,
			searchTerm,
			resultsPageAlias,
			filteredInventorySearchResultsByTab,
			activeInventoryTab,
			showTabs
		]
	);

	const handleSubmission = useCallback(
		(event) => {
			setFetchingResults(true);
			event?.preventDefault();
			if (
				!searchTerm?.trim() ||
				searchTerm.length < numberOfCharactersForFirstSearchNumber
			)
				return;
			setNewRelicCustomAttribute('wsSiteTextSearch-enterKey', searchTerm);
			if (!hasNoResults) {
				updateRecentSearches(searchTerm);
			}
			handleQuerySubmission(event);
			if (!modalIsOpen) {
				setModalIsOpen(true);
			}
			setFetchingResults(false);
		},
		[
			searchTerm,
			numberOfCharactersForFirstSearchNumber,
			hasNoResults,
			updateRecentSearches,
			handleQuerySubmission,
			modalIsOpen
		]
	);

	const handleClearHistory = useCallback(() => {
		try {
			localStorage.removeItem(g.RECENT_SEARCHES_KEY);
		} catch (error) {
			// add New Relic error tracking
		}
		inputElementRef.current?.focus();
		setRecentSearches([]);
		setModalIsOpen(false);
		trackEvent(widgetName, windowId, {
			element: 'clear history link',
			elementCTA: labels.get('CLEAR_HISTORY'),
			result: 'all chips removed',
			action: 'clicked'
		});
	}, [widgetName, windowId, labels]);

	const handleClearHistoryNoClose = useCallback(() => {
		try {
			localStorage.removeItem(g.RECENT_SEARCHES_KEY);
		} catch (error) {
			// add New Relic error tracking
		}
		inputElementRef.current?.focus();
		setRecentSearches([]);
		trackEvent(widgetName, windowId, {
			element: 'clear history link',
			elementCTA: labels.get('CLEAR_HISTORY'),
			result: 'all chips removed',
			action: 'clicked'
		});
	}, [widgetName, windowId, labels]);

	const isClickOutside = useCallback(
		(ref, target) => ref?.current && !ref.current.contains(target),
		[]
	);
	const isClickInside = useCallback(
		(ref, target) => ref?.current && ref.current.contains(target),
		[]
	);

	const handleClickOutside = useCallback(
		(event) => {
			const { target } = event;

			const isOutsideInput = isClickOutside(inputRef, target);
			const isOutsideDropdown = isClickOutside(dropDownRef, target);
			const isInsideEmbeddedDropdown = isClickInside(
				embeddedDropDownRef,
				target
			);
			const isOutsideEmbeddedDropdown = isClickOutside(
				embeddedDropDownRef,
				target
			);

			if (
				(isOutsideInput && isOutsideDropdown) ||
				(isEmbedded && isOutsideInput && isInsideEmbeddedDropdown) ||
				(isOutsideInput &&
					isOutsideEmbeddedDropdown &&
					!dropDownRef.current?.contains(event.target))
			) {
				closeModal();
			}
		},
		[
			isClickOutside,
			isClickInside,
			inputRef,
			dropDownRef,
			embeddedDropDownRef,
			isEmbedded,
			closeModal
		]
	);

	const handleOnChangeInput = useCallback(
		(searchTermValue) => {
			// Update based on context
			if (displayType === 'embedded' && !isEmbeddedOverrideActive) {
				setSearchTermEmbedded(searchTermValue);
			} else {
				setSearchTerm(searchTermValue);
			}

			// Modal visibility handling
			if (
				searchTermValue.length >= numberOfCharactersForFirstSearchNumber
			) {
				setModalIsOpen(true);
			} else if (
				searchTermValue.length === 0 &&
				recentSearches.length === 0
			) {
				setModalIsOpen(false);
			}
		},
		[
			numberOfCharactersForFirstSearchNumber,
			recentSearches.length,
			displayType,
			isEmbeddedOverrideActive
		]
	);

	const handleChipClick = useCallback((value) => {
		setSearchTerm(value);
		inputElementRef.current?.focus();
	}, []);

	useEffect(() => {
		const updateScreenWidth = () => setScreenWidth(window.innerWidth);
		updateScreenWidth();
		const throttledUpdateDropDownPosition = throttle(
			updateDropDownPosition,
			150
		);
		const throttledUpdateScreenWidth = throttle(updateScreenWidth, 150);
		const observer = new MutationObserver(throttledUpdateDropDownPosition);

		if (modalIsOpen) {
			updateDropDownPosition();
			window.addEventListener('resize', throttledUpdateDropDownPosition);
			window.addEventListener('resize', throttledUpdateScreenWidth);
			window.addEventListener('scroll', throttledUpdateDropDownPosition);
			document.addEventListener('mousedown', handleClickOutside);

			observer.observe(document.body, {
				childList: true,
				subtree: true,
				attributes: true
			});
		}
		return () => {
			window.removeEventListener(
				'resize',
				throttledUpdateDropDownPosition
			);
			window.removeEventListener(
				'scroll',
				throttledUpdateDropDownPosition
			);
			window.removeEventListener('resize', throttledUpdateScreenWidth);
			throttledUpdateDropDownPosition.cancel();
			document.removeEventListener('mousedown', handleClickOutside);
			observer.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalIsOpen]);

	// this is effectively the debounce for the fetch
	const debounceTimeoutRef = useRef(null);
	useEffect(() => {
		const trimmedSearchTerm = searchTerm.trim();
		if (
			trimmedSearchTerm.length >= numberOfCharactersForFirstSearchNumber
		) {
			setFetchingResults(true);
			renderStartTimeRef.current = performance.now();
			// Clear the previous timeout if it exists
			if (debounceTimeoutRef.current) {
				clearTimeout(debounceTimeoutRef.current);
			}
			// Set a new timeout to debounce the fetch
			debounceTimeoutRef.current = setTimeout(() => {
				fetchResults(trimmedSearchTerm);
			}, 250);
		} else if (
			trimmedSearchTerm.length < numberOfCharactersForFirstSearchNumber
		) {
			setRichContentToDisplay([]);
			setInventorySearchResults(undefined);
			setPageSearchResults(undefined);
			setFetchingResults(false);
		}
		// Cleanup function to clear the timeout if the component unmounts
		return () => {
			if (debounceTimeoutRef.current) {
				clearTimeout(debounceTimeoutRef.current);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm]);

	const isDisplayNavigation = displayType === g.NAVIGATION;

	useEffect(() => {
		if (isDisplayNavigation) {
			document.addEventListener(
				'ws-site-text-search-open-modal',
				openModal
			);
			return () => {
				document.removeEventListener(
					'ws-site-text-search-open-modal',
					openModal
				);
			};
		}
		return () => {};
	}, [isDisplayNavigation, openModal]);

	useEffect(() => {
		const trimmedSearchTerm = searchTermEmbedded.trim();

		if (
			trimmedSearchTerm?.length >= numberOfCharactersForFirstSearchNumber
		) {
			fetchEmbeddedResults(trimmedSearchTerm);
		} else if (
			trimmedSearchTerm?.length < numberOfCharactersForFirstSearchNumber
		) {
			setRichContentToDisplay([]);
			setInventorySearchResults(undefined);
			setPageSearchResults(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTermEmbedded]);

	useEffect(() => {
		// This effect only runs once to setup specific data for this component
		if (!hasFetchedAddInfo.current && displayType === 'embedded') {
			fetchAddInfo();
			hasFetchedAddInfo.current = true;
		}
		if (isEmbedded && isBrowser && window) {
			const params = new URLSearchParams(window.location.search);
			if (params.has('search')) {
				setSearchTermEmbedded(params?.get('search'));
			}
		}
		if (isBrowser && window) {
			addNewRelicPageAction('wsSiteTextSearchDebug', {
				eventName: 'ws-site-text-search on page',
				type: displayType.toString()
			});
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// This will set current suggestions, vehicles, and allVehiclesPath based on
	// the active tab or otherwise the default inventory results
	const prepareInventoryResults = useMemo(() => {
		let suggestions = [];
		let vehicles = [];
		let allVehiclesPath = '';

		if (
			filteredInventorySearchResultsByTab &&
			(filteredInventorySearchResultsByTab.new ||
				filteredInventorySearchResultsByTab.used ||
				filteredInventorySearchResultsByTab.certified)
		) {
			if (
				activeInventoryTab &&
				filteredInventorySearchResultsByTab[activeInventoryTab]
			) {
				suggestions =
					filteredInventorySearchResultsByTab[activeInventoryTab]
						.suggestions || [];
				vehicles =
					filteredInventorySearchResultsByTab[activeInventoryTab]
						.vehicles || [];
				allVehiclesPath =
					filteredInventorySearchResultsByTab[activeInventoryTab]
						.allVehiclesPath || '';
			}
		} else {
			suggestions =
				filteredInventorySearchResultsByTab?.suggestions || [];
			vehicles = filteredInventorySearchResultsByTab?.vehicles || [];
			allVehiclesPath =
				filteredInventorySearchResultsByTab?.allVehiclesPath || '';
		}

		return {
			suggestions,
			vehicles,
			allVehiclesPath
		};
	}, [filteredInventorySearchResultsByTab, activeInventoryTab]);

	let stsContainerContent = null;

	const searchContextValue = {
		fetchingResults,
		handleChipClick,
		handleOnChangeInput,
		handleSubmission,
		numberOfCharactersForFirstSearchNumber,
		onClear: () => {
			setSearchTerm('');
			setSearchTermEmbedded('');
		},
		searchTerm,
		searchTermEmbedded,
		setSearchTerm,
		setSearchTermEmbedded
	};

	const uiContextValue = {
		closeModal,
		displayType,
		dropDownPosition,
		dropDownRef,
		embeddedDropDownRef,
		inputElementRef,
		inputRef,
		inputWidth,
		isEmbeddedOverrideActive,
		isMobile,
		labels,
		locale,
		modalIsOpen,
		openModal,
		openButtonRef,
		pageAlias,
		screenWidth,
		setIsEmbeddedOverrideActive,
		setModalIsOpen,
		updateDropDownPosition,
		widgetName,
		windowId
	};

	const resultsContextValue = {
		activeInventoryTab,
		addInfo,
		accountId,
		disabledTabs,
		fetchEmbeddedResults,
		fetchResults,
		handleClearHistory,
		handleClearHistoryNoClose,
		handleLinkClick,
		hasNoResults,
		hasSingleVehicle,
		// keep to pass to InventoryTabs
		inventoryResults: filteredInventorySearchResultsByTab,
		noResultsLinks: NO_RESULTS_LINKS,
		noResultsRichContent,
		pageSearchResults,
		pageSearchResultsEmbedded,
		preparedInventoryResults: prepareInventoryResults,
		richContentToDisplay,
		recentSearches,
		removeRecentSearch,
		reorderRecentSearches,
		richContentToDisplayEmbedded,
		setActiveInventoryTab,
		showTabs
	};

	switch (displayType) {
		case 'embedded':
			stsContainerContent = (
				<>
					{(!isEmbeddedOverrideActive || !isMobile) && (
						<InputDisplay />
					)}
					<StaticEmbedded />
					{modalIsOpen && !isMobile && isEmbeddedOverrideActive && (
						<DropDown />
					)}
					{modalIsOpen && isMobile && isEmbeddedOverrideActive && (
						<FlyOut />
					)}
				</>
			);
			break;
		case 'input':
			stsContainerContent = (
				<>
					<InputDisplay />
					{modalIsOpen && !isMobile && <DropDown />}
					{modalIsOpen && isMobile && <FlyOut />}
				</>
			);
			break;
		case 'button':
		default:
			stsContainerContent = (
				<>
					<ButtonDisplay data-testid="sts-input" />
					{modalIsOpen && <FlyOut />}
				</>
			);
	}

	return (
		<UIContext.Provider value={uiContextValue}>
			<SearchContext.Provider value={searchContextValue}>
				<ResultsContext.Provider value={resultsContextValue}>
					{stsContainerContent}
				</ResultsContext.Provider>
			</SearchContext.Provider>
		</UIContext.Provider>
	);
};

export default Container;
