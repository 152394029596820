import React, { useContext, useState, useRef, useEffect } from 'react';
import { SearchContext } from '../contexts';

const LoadingAnimation = () => {
	const [shouldAnimate, setShouldAnimate] = useState(false);
	const svgRef = useRef(null);
	const lineRef = useRef(null);

	const { fetchingResults } = useContext(SearchContext);

	useEffect(() => {
		if (fetchingResults) {
			setShouldAnimate(true);
			if (lineRef.current) {
				lineRef.current.classList.add('hidden');
				lineRef.current.classList.remove('visible');
			}
			return () => {};
		}

		if (svgRef.current && lineRef.current) {
			const currentSvgRef = svgRef.current;

			const handleAnimationEnd = () => {
				setShouldAnimate(false);
				lineRef.current.classList.remove('hidden');
				lineRef.current.classList.add('visible');
			};

			currentSvgRef.addEventListener(
				'animationiteration',
				handleAnimationEnd
			);

			return () => {
				currentSvgRef.removeEventListener(
					'animationiteration',
					handleAnimationEnd
				);
			};
		}

		return undefined;
	}, [fetchingResults]);

	return (
		<div className="loading-animation-container ml-1 mr-1">
			<svg
				ref={svgRef}
				width="30"
				height="30"
				className={`${shouldAnimate ? 'animating' : ''}`}
			>
				<circle
					r="11.25"
					cx="12.25"
					cy="12.25"
					className="search-circle-outer"
				/>
				<circle
					r="11.25"
					cx="12.25"
					cy="12.25"
					className="search-circle-inner"
				/>
				<line
					ref={lineRef}
					x1="20.5"
					y1="20.5"
					x2="29.7071"
					y2="29.2929"
					className="search-handle visible"
				/>
			</svg>
		</div>
	);
};

export default LoadingAnimation;
