/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';

const PaginationItem = ({ pageCallback, currentPage, pageIndex }) => {
	const isCurrent = pageIndex === currentPage;
	const active = isCurrent ? 'active' : null;
	const ariaCurrent = isCurrent ? 'page' : null;
	const displayNumber = pageIndex + 1;

	return (
		<li
			className={active}
			data-testid={`site-text-search-pagination-page-${displayNumber}-li`}
		>
			<a
				aria-current={ariaCurrent}
				key={pageIndex}
				href="#page-body"
				onClick={() => pageCallback(pageIndex)}
				aria-label={`Goto Page ${displayNumber}`}
				data-testid={`site-text-search-pagination-page-${displayNumber}-a`}
				title={
					isCurrent
						? `Currently viewing page ${displayNumber} of matching results`
						: `Go to page ${displayNumber} of matching results`
				}
			>
				{displayNumber}
			</a>
		</li>
	);
};

PaginationItem.propTypes = {
	pageCallback: PropTypes.func,
	currentPage: PropTypes.number.isRequired,
	pageIndex: PropTypes.number.isRequired
};

export default PaginationItem;
