import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { usePrefs } from 'wsm-common-data';
import ResultsHeading from './ResultsHeading';
import PhoneLink from './PhoneLink';
import EmailLink from './EmailLink';
import { UIContext, ResultsContext } from '../../contexts';

const ContactLinksList = ({
	phoneNumbers = [],
	email = '',
	ariaLabelledById = ''
}) => {
	const { contactUsHeadingLabel } = usePrefs();

	const { handleLinkClick, richContentToDisplay, accountId } =
		useContext(ResultsContext);

	const { labels } = useContext(UIContext);

	const links = richContentToDisplay.reduce((linksArray, richContentName) => {
		const [richContentType, richContentDepartment] =
			richContentName.split('-');
		if (richContentType === 'phone' && richContentDepartment) {
			const phoneObject = phoneNumbers.find(
				(i) =>
					i.dept ===
					richContentDepartment.charAt(0).toUpperCase() +
						richContentDepartment.slice(1)
			);
			if (phoneObject?.number) {
				const { number, dept, type } = phoneObject;
				linksArray.push(
					<PhoneLink
						department={dept}
						number={number}
						key={`phone-${dept}`}
						accountRef={accountId}
						phoneRef={type.toUpperCase()}
					/>
				);
			}
		} else if (richContentType === 'email') {
			if (email) {
				linksArray.push(
					<EmailLink
						email={email}
						key={email}
						handleLinkClick={handleLinkClick}
					>
						{labels.get('SEND_US_AN_EMAIL')}
					</EmailLink>
				);
			}
		}
		return linksArray;
	}, []);

	const headingLabel = labels.get(contactUsHeadingLabel);
	const optionalAttributes = {
		...(headingLabel && { 'aria-labelledby': ariaLabelledById })
	};

	return links.length ? (
		<div {...optionalAttributes}>
			{headingLabel && (
				<ResultsHeading
					label={headingLabel}
					ariaLabelledById={ariaLabelledById}
				/>
			)}
			<ul className="list-unstyled list-spaced mx-4 mb-4">{links}</ul>
		</div>
	) : null;
};

ContactLinksList.propTypes = {
	phoneNumbers: PropTypes.arrayOf(PropTypes.shape({})),
	email: PropTypes.string,
	ariaLabelledById: PropTypes.string
};
export default ContactLinksList;
