import { trackEvent } from 'ddc-track-event';

const closeModalHelper = (
	widgetName,
	windowId,
	setModalIsOpen,
	setSearchTerm,
	isEmbeddedOverrideActive,
	setIsEmbeddedOverrideActive
) => {
	window.DDC.WidgetData[widgetName] = {
		modalIsOpen: false
	};
	setModalIsOpen(false);
	setSearchTerm('');
	if (isEmbeddedOverrideActive) {
		setIsEmbeddedOverrideActive(false);
	}
	document.body.style.overflow = 'auto';
	trackEvent(widgetName, windowId, {
		action: 'clicked',
		element: 'close button',
		result: 'search modal closed'
	});
};

export default closeModalHelper;
