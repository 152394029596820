const throttle = (func, delay) => {
	let lastCall = 0;
	let timeoutId;

	const throttled = (...args) => {
		const now = new Date().getTime();
		if (now - lastCall < delay) {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
			timeoutId = setTimeout(() => {
				lastCall = now;
				func(...args);
			}, delay - (now - lastCall));
			return;
		}
		lastCall = now;
		func(...args);
	};

	throttled.cancel = () => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
	};

	return throttled;
};

export default throttle;
