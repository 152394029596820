import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import VehicleCard from './VehicleCard';
import { UIContext } from '../contexts';

const VehicleCards = ({ vehicles }) => {
	const { inputWidth, labels } = useContext(UIContext);

	const singleColumnWidth = 600;
	const isDoubleColumn = inputWidth > singleColumnWidth;
	if (vehicles?.length > 0) {
		let keyCount = 0;
		return (
			<div
				data-testid="sts-vehicle-cards"
				role="region"
				aria-labelledby="vehicle-cards-heading"
				className={`${isDoubleColumn ? '' : 'pb-5'}`}
			>
				<h3 id="vehicle-cards-heading" className="sr-only">
					{labels.get('VEHICLE_SEARCH_RESULTS')}
				</h3>
				{vehicles.slice(0, 5).map((vehicle) => {
					const {
						path,
						images: [image1],
						title,
						price,
						odometer,
						odometerUnit,
						condition
					} = vehicle;

					const titleString = Array.isArray(title)
						? title.join(' ')
						: title;

					const image = image1;
					const capitalizedCondition = condition.toUpperCase();
					keyCount += 1;
					return (
						<VehicleCard
							href={path || null}
							imageUrl={image?.url}
							key={keyCount}
							title={titleString}
							price={price || ''}
							odometer={odometer || ''}
							odometerUnit={odometerUnit || ''}
							condition={capitalizedCondition}
						/>
					);
				})}
			</div>
		);
	}
	return null;
};

VehicleCards.propTypes = {
	vehicles: PropTypes.arrayOf(
		PropTypes.shape({
			path: PropTypes.string,
			title: PropTypes.arrayOf(PropTypes.string),
			images: PropTypes.arrayOf(PropTypes.shape({})),
			price: PropTypes.string,
			odometer: PropTypes.string,
			odometerUnit: PropTypes.string,
			condition: PropTypes.string
		})
	)
};

export default VehicleCards;
