import { SET_WIDGET_DATA } from '../actions/types';

const baseEndpointUrl = (state = {}, { type, payload }) => {
	switch (type) {
		case SET_WIDGET_DATA:
			return payload;
		default:
			return state;
	}
};

export default baseEndpointUrl;
