const isEnoughAddData = (richContentToDisplay, addInfo) => {
	if (!addInfo || richContentToDisplay?.length === 0) {
		return false;
	}

	const isNotEmpty = (obj) => {
		if (Array.isArray(obj)) {
			return obj.length > 0;
		}
		if (obj) {
			return Object.keys(obj).length > 0;
		}
		return false;
	};

	const CONTENT_TYPES = {
		HOURS: [
			'hours-sales',
			'hours-service',
			'hours-parts',
			'hours-commercial'
		],
		LOCATION: ['address'],
		CONTACT: [
			'phone-sales',
			'phone-service',
			'phone-parts',
			'phone-commercial',
			'email'
		]
	};
	const { hours = {}, mapLink, dealerInfo = {}, phoneNumbers = [] } = addInfo;

	const hasRequestedContent = (items, targetKeys) =>
		items.some((item) => targetKeys.includes(item));

	if (hasRequestedContent(richContentToDisplay, CONTENT_TYPES.HOURS)) {
		if (isNotEmpty(hours)) {
			return true;
		}
	}

	if (hasRequestedContent(richContentToDisplay, CONTENT_TYPES.LOCATION)) {
		if (isNotEmpty(mapLink) || isNotEmpty(dealerInfo)) {
			return true;
		}
	}

	if (hasRequestedContent(richContentToDisplay, CONTENT_TYPES.CONTACT)) {
		if (isNotEmpty(phoneNumbers)) {
			return true;
		}
	}

	return false;
};

export default isEnoughAddData;
