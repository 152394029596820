import React, { useContext } from 'react';
import Chip from './Chip';
import { UIContext, ResultsContext } from '../contexts';

const RecentSearches = () => {
	const { recentSearches, handleClearHistory } = useContext(ResultsContext);

	const { labels } = useContext(UIContext);

	return (
		<div role="group">
			<div className="d-flex justify-content-between align-items-center">
				<div
					id="recent-searches-heading"
					className="mt-0 h4 text-capitalize"
				>
					{labels.get('RECENT_SEARCHES')}
				</div>
				<button
					className="btn btn-link p-0 text-danger font-weight-light btn-sm"
					onClick={handleClearHistory}
					type="button"
					aria-label={labels.get('CLEAR_SEARCH_HISTORY')}
				>
					{labels.get('CLEAR_HISTORY')}
				</button>
			</div>
			{recentSearches?.slice(0, 5).map((recentSearch) => (
				<Chip key={recentSearch.value} recentSearch={recentSearch} />
			))}
		</div>
	);
};

export default RecentSearches;
