import React, { useEffect, useState, useContext, useRef } from 'react';
import { createPortal } from 'react-dom';
import { setClassNames } from 'ddc-classnames-js';
import { usePrefs } from 'wsm-common-data';
import { g } from '../global-constants';
import useFocusTrap from '../hooks/useFocusTrap';
import InputDisplay from './InputDisplay';
import VehicleCards from './VehicleCards';
import SearchLinks from './SearchLinks';
import InventoryTabs from './InventoryTabs';
import RichContent from './richContent/RichContent';
import NoResults from './NoResults';
import RecentSearches from './RecentSearches';
import shouldShowRecentSearches from '../util/shouldShowRecentSearches';
import hasEmptyResults from '../util/hasEmptyResults';
import hasResults from '../util/hasResults';
import ContentColumnizer from './ContentColumnizer';
import useAriaLive from '../hooks/useAriaLive';
import isEnoughAddData from '../util/isEnoughAddData';
import { SearchContext, UIContext, ResultsContext } from '../contexts';

const FlyOut = () => {
	const flyOutRef = useRef(null);

	const {
		searchTerm,
		numberOfCharactersForFirstSearchNumber,
		fetchingResults
	} = useContext(SearchContext);

	const {
		openButtonRef,
		closeModal,
		inputElementRef,
		labels,
		modalIsOpen,
		isMobile,
		screenWidth,
		setIsEmbeddedOverrideActive
	} = useContext(UIContext);

	const {
		recentSearches,
		pageSearchResults,
		preparedInventoryResults,
		handleLinkClick,
		addInfo,
		richContentToDisplay,
		noResultsLinks,
		showTabs,
		hasSingleVehicle
	} = useContext(ResultsContext);

	const prefs = usePrefs();
	const [localmodalIsOpen, setLocalmodalIsOpen] = useState(false);

	const { suggestions, vehicles, allVehiclesPath } =
		preparedInventoryResults || {};

	const isSearchTermLongEnough =
		searchTerm?.length >= numberOfCharactersForFirstSearchNumber;

	const hasResultsLocal = hasResults(
		pageSearchResults,
		suggestions,
		vehicles
	);

	const hasEmptyResultsLocal = hasEmptyResults(
		pageSearchResults,
		suggestions,
		vehicles
	);

	const announceText = useAriaLive(
		isSearchTermLongEnough,
		hasResultsLocal,
		hasEmptyResultsLocal
	);

	const hasRichContentToDisplay = isEnoughAddData(
		richContentToDisplay,
		addInfo
	);

	const shouldRenderNoResults =
		!hasResultsLocal &&
		hasEmptyResultsLocal &&
		!hasRichContentToDisplay &&
		!fetchingResults;

	useFocusTrap(
		[openButtonRef, flyOutRef],
		null,
		flyOutRef,
		null,
		openButtonRef,
		modalIsOpen,
		closeModal
	);

	const isWideScreen = screenWidth > 768;

	const classNames = [
		'sts-search-flyout',
		'box',
		'pt-4 pb-4 pl-5 pr-5',
		localmodalIsOpen && 'slide-left',
		!isMobile && !isWideScreen ? 'one-column' : isMobile && 'mobile',
		isWideScreen && 'two-columns'
	];

	const closeClassNames = [
		'btn-unstyled text-link-muted',
		'py-0',
		'px-0',
		'my-3',
		'border-neutral-0-alpha-3',
		'float-right'
	];

	const viewLinkLabel = labels.get('VIEW_ALL_VEHICLES_SITE_SEARCH');
	const handleClick = () => {
		handleLinkClick('allInventoryLink', viewLinkLabel, '', '');
	};

	const onClose = () => {
		setIsEmbeddedOverrideActive(false);
		closeModal();
	};

	let ariaLabelledBy;
	if (searchTerm) {
		ariaLabelledBy = 'search-modal-headline';
	} else if (recentSearches?.length > 0) {
		ariaLabelledBy = 'recent-searches-heading';
	} else {
		ariaLabelledBy = 'default-flyout-label';
	}

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setLocalmodalIsOpen(true);
		}, 50);

		if (modalIsOpen) {
			document.body.style.overflow = 'hidden';
			inputElementRef.current?.focus();
		}

		return () => {
			clearTimeout(timeoutId);
		};
	}, [modalIsOpen, inputElementRef]);

	const flyOutContent = (
		<>
			{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
			<div
				className="sts-search-modal clearfix"
				role="none"
				data-testid="sts-flyout-background"
				onClick={() => onClose(g.CLOSE_BUTTON)}
				data-widget-name="ws-site-text-search"
			/>
			<div
				className={setClassNames(classNames)}
				role="dialog"
				aria-labelledby={ariaLabelledBy}
				ref={flyOutRef}
				tabIndex="-1"
				data-testid="sts-flyout"
				data-widget-name="ws-site-text-search"
			>
				<div
					id="default-flyout-label"
					className="sr-only"
					aria-live="polite"
					aria-atomic="true"
				>
					{labels.get(prefs.searchPlaceholderLabel)}
				</div>
				<div aria-live="assertive" className="sr-only">
					{announceText}
				</div>
				<div className="pl-0 py-3 pr-0 clearfix">
					<h2
						className="ddc-heading-3 font-weight-bold text-capitalize float-left mt-3 ddc-font-size-xlarge"
						id="search-modal-headline"
					>
						{labels.get('SEARCH')}
					</h2>
					<button
						type="button"
						tabIndex="0"
						aria-label={labels.get('CLOSE_MODAL')}
						className={setClassNames(closeClassNames)}
						data-testid="flyout-close-button"
						onClick={() => onClose(g.CLOSE_BUTTON)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								onClose(g.CLOSE_BUTTON);
								setTimeout(() => {
									openButtonRef.current?.focus(); // Focus on the button after a delay
								}, 10);
							}
						}}
					>
						<i
							className="ddc-icon ddc-icon-remove2 icon-size-2"
							aria-hidden="true"
						/>
					</button>
				</div>

				<InputDisplay />
				<div className="mt-5 clearfix">
					{shouldShowRecentSearches(
						searchTerm,
						hasResultsLocal,
						hasEmptyResultsLocal,
						recentSearches
					) && <RecentSearches />}
					{isSearchTermLongEnough && (
						<div>
							<h2 id="results-heading" className="sr-only">
								{labels.get('SEARCH_RESULTS')}
							</h2>
							{showTabs ? (
								<InventoryTabs
									hasRichContentToDisplay={
										hasRichContentToDisplay
									}
								/>
							) : (
								<>
									{allVehiclesPath &&
										!shouldRenderNoResults &&
										!hasSingleVehicle && (
											<a
												onClick={handleClick}
												href={allVehiclesPath}
												className="font-weight-bold py-3 px-2"
											>
												<span>
													{viewLinkLabel}
													<i
														className="ddc-icon ddc-icon-arrow-details ml-3"
														aria-hidden="true"
													/>
												</span>
											</a>
										)}
									<div className="row">
										<ContentColumnizer
											inputWidth={screenWidth}
											vehicles={vehicles?.length > 0}
										>
											{vehicles?.length > 0 ? (
												<VehicleCards
													vehicles={vehicles}
												/>
											) : null}
											{suggestions?.length > 0 ? (
												<SearchLinks
													type="inventory"
													data={suggestions}
												/>
											) : null}
											{pageSearchResults?.length > 0 ? (
												<SearchLinks
													type="page"
													data={pageSearchResults}
												/>
											) : null}
											{shouldRenderNoResults && (
												<>
													<NoResults />
													<SearchLinks
														type="no-results"
														data={noResultsLinks}
													/>
												</>
											)}
											{(hasRichContentToDisplay ||
												shouldRenderNoResults) && (
												<RichContent />
											)}
										</ContentColumnizer>
									</div>
								</>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	);

	return createPortal(flyOutContent, document.body);
};

export default FlyOut;
