/*
 * WARNING: This file is owned by ws-scripts and can not be edited!
 *
 * NOTE: The eslint-disable-lines are needed because this file won't lint
 * when it's in the ws-scripts repo because those files only exist in the
 * widget. It's safe because the entire application won't work if those
 * weren't really resolved. It would be really apparent quickly.
 */

import {
	combineReducers,
	configureStore as rtkConfigureStore
} from '@reduxjs/toolkit';
import requestDataReducer from './reducers/request-data';
import prefsReducer from './reducers/prefs';
import labelsReducer from './reducers/labels';
import sitemapReducer from './reducers/sitemap';
import flagsReducer from './reducers/flags';
import customMiddleware from '../middleware'; // eslint-disable-line import/no-unresolved

const getReducers = () => {
	let oldRootReducer = {};
	let rootReducer = {};
	const moduleDeps = [];
	try {
		oldRootReducer = require('../reducers').default; // eslint-disable-line import/no-unresolved
		moduleDeps.push('../reducers');
	} catch (e) {
		// reducers does not exist in this widget
	}
	try {
		rootReducer = require('../features').default; // eslint-disable-line import/no-unresolved
		moduleDeps.push('../features');
	} catch (e) {
		// features slices does not exist in this widget
	}
	return [{ ...oldRootReducer, ...rootReducer }, moduleDeps];
};

const providedReducers = {
	requestData: requestDataReducer,
	prefs: prefsReducer,
	labels: labelsReducer,
	sitemap: sitemapReducer,
	flags: flagsReducer
};

const isBrowser = typeof window !== 'undefined';

if (process.env.NODE_ENV === 'development') {
	if (isBrowser) {
		// Note: This must be the last middleware in the chain so that it logs actions
		// instead of thunks or Promises.

		// Use an actionTransformer to get the widget name into the output since
		// we have multiple stores running on one page. It may seem like using
		// titleFormatter makes more sense for this purpose, but doing so loses the
		// readability improvement coloring added in 2.10.0 of redux-logger. As of
		// 3.0.6, there is no way to get colors without using the default title formatter.
		// With the below action transformer and default title formatter, we get:
		// action ws-example - MIDDLEWARE_TEST @ 17:47:41.612 (in 0.30 ms)
		const actionTransformer = (action) => ({
			...action,
			type: `ws-site-text-search - ${action.type}`
		});

		const { createLogger } = require('redux-logger');
		const logger = createLogger({ actionTransformer, duration: true });
		customMiddleware.push(logger);
	}
}

export function configureStore(preloadedState) {
	const [reducers, moduleDeps] = getReducers();
	const store = rtkConfigureStore({
		reducer: {
			...reducers,
			...providedReducers
		},
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware().concat(customMiddleware),
		preloadedState
	});

	if (module.hot) {
		// 3rd arg callback errorHandler for module.hot.accept not functioning for resolution of uncaught error when directory is missing
		// instead, use above check for existence of reducers directories to determine which to provide to module depedencies array
		module.hot.accept(moduleDeps, () => {
			const [reloadedReducers, _] = getReducers();
			store.replaceReducer(
				combineReducers({
					...reloadedReducers,
					...providedReducers
				})
			);
		});
	}

	return store;
}
