import React, { useContext, useState } from 'react';
import { setClassNames } from 'ddc-classnames-js';
import { addNewRelicPageAction } from 'ddc-new-relic';
import RichContent from './richContent/RichContent';
import useFocusTrap from '../hooks/useFocusTrap';
import SearchLinks from './SearchLinks';
import NoResults from './NoResults';
import hasEmptyResults from '../util/hasEmptyResults';
import hasResults from '../util/hasResults';
import ContentColumnizer from './ContentColumnizer';
import Pagination from './Pagination';
import isEnoughAddData from '../util/isEnoughAddData';
import { SearchContext, UIContext, ResultsContext } from '../contexts';

const StaticEmbedded = () => {
	const { searchTermEmbedded, numberOfCharactersForFirstSearchNumber } =
		useContext(SearchContext);

	const {
		embeddedDropDownRef, // This should be used in the render!
		inputRef,
		inputElementRef,
		modalIsOpen,
		closeModal,
		labels
	} = useContext(UIContext);

	const {
		recentSearches,
		pageSearchResultsEmbedded,
		addInfo,
		richContentToDisplayEmbedded,
		noResultsLinks
	} = useContext(ResultsContext);

	const suggestions = undefined;
	const vehicles = undefined;

	const [currentPage, setCurrentPage] = useState(0);
	const issearchTermEmbeddedLongEnough =
		searchTermEmbedded?.length >= numberOfCharactersForFirstSearchNumber;
	const fakePage = [[], []];
	const visibleLimit = 15;

	const hasResultsLocal = hasResults(
		pageSearchResultsEmbedded,
		suggestions,
		vehicles
	);

	const hasEmptyResultsLocal = hasEmptyResults(
		pageSearchResultsEmbedded,
		suggestions,
		vehicles
	);

	const hasrichContentToDisplayEmbedded = isEnoughAddData(
		richContentToDisplayEmbedded,
		addInfo
	);

	const shouldRenderNoResults =
		!hasResultsLocal &&
		hasEmptyResultsLocal &&
		!hasrichContentToDisplayEmbedded;

	useFocusTrap(
		[inputRef, embeddedDropDownRef],
		embeddedDropDownRef,
		null,
		inputElementRef,
		null,
		modalIsOpen,
		closeModal
	);

	const classNames = ['sts-search-dropdown', 'p-4'];

	const pageCallback = (page) => {
		setCurrentPage(page);
		addNewRelicPageAction('wsSiteTextSearchDebug', {
			eventName: 'ws-site-text-search pagination event',
			page
		});
	};

	if (
		!recentSearches?.length &&
		(!searchTermEmbedded ||
			!issearchTermEmbeddedLongEnough ||
			(pageSearchResultsEmbedded === undefined &&
				suggestions === undefined &&
				vehicles === undefined))
	) {
		return null;
	}

	if (pageSearchResultsEmbedded?.length > visibleLimit) {
		pageSearchResultsEmbedded.forEach((val, index) => {
			if (index < visibleLimit) {
				fakePage[0].push(val);
			} else {
				fakePage[1].push(val);
			}
		});
	}

	const searchLinkMarkup = () => {
		let markup;
		if (pageSearchResultsEmbedded?.length > visibleLimit) {
			markup = (
				<div className="search-link-wrapper">
					<SearchLinks
						type="page"
						data={fakePage[currentPage]}
						isEmbedded
					/>
					<div className="row">
						<div className="col-xs-12">
							<Pagination
								pageCallback={pageCallback}
								currentPage={currentPage}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			markup = (
				<SearchLinks
					type="page"
					data={pageSearchResultsEmbedded}
					isEmbedded
				/>
			);
		}
		return markup;
	};

	const dropDownContent = (
		<div
			ref={embeddedDropDownRef}
			className={setClassNames(classNames)}
			data-testid="sts-input-dropdown"
			role="dialog"
			aria-labelledby={
				searchTermEmbedded
					? 'results-heading'
					: 'recent-searches-heading'
			}
			aria-modal="true"
		>
			{issearchTermEmbeddedLongEnough && (
				<div>
					<h2 id="results-heading" className="sr-only">
						{labels.get('SEARCH_RESULTS')}
					</h2>
					<ContentColumnizer vehicles={vehicles?.length > 0}>
						{pageSearchResultsEmbedded?.length > 0
							? searchLinkMarkup()
							: null}
						{shouldRenderNoResults && (
							<>
								<NoResults />
								<SearchLinks
									type="no-results"
									showResults="true"
									data={noResultsLinks}
								/>
							</>
						)}
						{(hasrichContentToDisplayEmbedded ||
							shouldRenderNoResults) && <RichContent />}
					</ContentColumnizer>
				</div>
			)}
		</div>
	);

	return dropDownContent;
};

export default StaticEmbedded;
