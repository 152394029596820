import React, { useContext } from 'react';
import { usePrefs } from 'wsm-common-data';
import { useSelector } from 'react-redux';
import { setClassNames } from 'ddc-classnames-js';
import { trackEvent } from 'ddc-track-event';
import { g } from '../global-constants';
import LoadingAnimation from './LoadingComponent';
import { SearchContext, UIContext } from '../contexts';

const InputDisplay = () => {
	const {
		searchTerm,
		searchTermEmbedded,
		handleOnChangeInput,
		handleSubmission,
		onClear,
		setSearchTerm
	} = useContext(SearchContext);

	const {
		displayType,
		inputElementRef,
		inputRef,
		isEmbeddedOverrideActive,
		isMobile,
		labels,
		modalIsOpen,
		openModal,
		setIsEmbeddedOverrideActive,
		widgetName,
		windowId
	} = useContext(UIContext);

	const onInputHandler = () => {
		setIsEmbeddedOverrideActive(true);
		if (!isEmbeddedOverrideActive) {
			setSearchTerm('');
		}
	};

	const { searchPlaceholderLabel } = usePrefs();

	const { pageAlias } = useSelector((state) => state.widgetData);

	const inputId = `${pageAlias}_${windowId}`;

	const inputClassNames = [
		'form-control',
		'site-text-search-input',
		'font-size-ios-zoom-override',
		'form-control-transparent',
		'pl-0'
	];
	const clearClassNames = [
		'btn-unstyled text-link-muted',
		'py-3',
		'px-4',
		'btn-sm',
		'border-neutral-0-alpha-3',
		!modalIsOpen && 'hidden'
	];

	const inputContainerClassNames = [
		'input-container text-left',
		(searchTerm || searchTermEmbedded) && 'has-content'
	];

	const displayValue = () => {
		if (displayType === 'embedded' && !isEmbeddedOverrideActive) {
			return searchTermEmbedded;
		}
		return searchTerm;
	};

	return (
		<>
			<div
				ref={inputRef}
				data-type={displayType}
				data-testid="sts-input-display"
				className="site-text-search-wrapper"
			>
				<form
					className="form-control spacing-reset d-flex h-auto"
					autoComplete="off"
					data-form-tracking-id="SITEWIDE_SEARCH"
					data-form-tracking-type="typedSearch"
					role="search"
					onSubmit={(e) => handleSubmission(e)}
				>
					<LoadingAnimation />
					<div className={setClassNames(inputContainerClassNames)}>
						<label
							id="site-search-label"
							htmlFor={inputId}
							className="floating-placeholder"
						>
							{labels.get(searchPlaceholderLabel)}
						</label>
						<input
							id={inputId}
							className={setClassNames(inputClassNames)}
							aria-label={labels.get('SEARCH_BY_MAKE_MODEL_ETC')}
							onClick={() => {
								openModal?.();
								onInputHandler?.();
							}}
							value={displayValue()}
							onFocus={() => {
								if (!isMobile && !modalIsOpen) {
									openModal?.();
									onInputHandler?.();
								}
							}}
							onChange={(e) =>
								handleOnChangeInput(e.target.value)
							}
							maxLength={100}
							data-testid="sts-input"
							ref={inputElementRef}
						/>
					</div>
					{!(
						displayType === g.EMBEDDED && !isEmbeddedOverrideActive
					) && (
						<button
							type="button"
							tabIndex="0"
							aria-label={labels.get('CLEAR')}
							className={setClassNames(clearClassNames)}
							onClick={() => {
								trackEvent(widgetName, windowId, {
									action: 'clicked',
									element: 'clear button',
									result: 'search field cleared'
								});
								onClear();
								inputElementRef.current?.focus();
								// Place cursor at the end of empty input
								inputElementRef.current?.setSelectionRange(
									inputElementRef.current.value.length,
									inputElementRef.current.value.length
								);
							}}
							data-testid="clear-button"
						>
							{labels.get('CLEAR')}
						</button>
					)}
				</form>
			</div>
		</>
	);
};

export default InputDisplay;
